
.admin-top {
    align-items: center;
}
.sort-item{
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    box-sizing: border-box;
    .sort-info{
        .selected-num-title{
            width: 120px;
            display: inline-block;
        }
    }
    .del{
        cursor: pointer;
        &:hover{
            color: #f56c6c;
        }
    }
    ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    ::v-deep.el-checkbox__input.is-checked+.el-checkbox__label {
        color: #1E33E3;
    }
}
.video-list-content{
    display: flex;
    flex-direction: column;
    height:calc(100vh - 200px);
    padding-left: 20px;
    .video-list{
        height: 100%;
        ::v-deep.el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .video-item{
            display: flex;
            flex-wrap: wrap;
            .top-animate{
                width: calc(25% - 20px);
                margin: 0 20px 15px 0;
                position: relative;
                overflow: hidden;
                .video-img{
                    width: 100%;
                    height: 0;
                    padding-bottom: 66.67%;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    background: #F1F2FF;
                    video{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 257px;
                        height: 140px;
                        /*max-width: 100%;*/
                        /*min-height: 140px;*/
                    }
                }
                a{
                    color: #333;
                }
                .info-title{
                    background: #F1F2FF;
                    padding: 0 13px 16px;
                    p{
                        padding: 10px 0 0;
                        margin: 0;
                    }
                    .text{
                        display:block;
                        max-width: 370px;
                        max-height: 39px;
                        font-size: 14px;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .fl-title,.time-title{
                        color: #666;
                        font-size: 12px;
                    }
                    .video-content{
                        display: flex;
                        align-items: center;
                        .video-content-left{
                            flex: 1;
                        }
                        .video-content-right{
                            width: 24px;
                            height: 24px;
                            display: inline-block;
                            cursor: pointer;
                            &:hover{
                                transform: scale(1.2);
                            }
                            img{
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
                ::v-deep.el-checkbox-group{
                    position: absolute;
                    left: 188px;
                    top: 6px;
                    width: 20px;
                }
                ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
                    background-color: #1E33E3;
                    border-color: #1E33E3;
                }
            }
        }
    }
}
.upload-btn-video {
    position: relative;
    padding: 7px 16px;
    background: #594FEE;
    border-color: #594FEE;
    cursor: pointer;
    &:hover {
        background: #2338E6;
        border-color: #2338E6;
    }
    input {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
.video-request {
    display: flex;
    flex-direction: column;
    color: #BCB9CB;
    font-size: 14px;
}
.video-show {
    margin: 0 0 10px;
    width: 300px;
    height: 150px;
    video {
        width: 100%;
        height: 100%;
    }
}
.video-btn{
    display: flex;
    justify-content: center;
}
